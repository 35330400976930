import React, {useState} from 'react'
import logo from './logo.svg';
import './styles/style.css';
import players from './images/players.jpg'
import result from './images/result.jpg'
import { integralVersion, listVersions } from './datas/dataGame';
import { handleChangeInput } from './athea/formControl';
import victoryImg from './images/winPyramids.png';
import winStone from './images/WinPierreWin.png';
import littleStone from './images/petitePierre.png';
import bigStone from './images/grandePierre.png'
import rays from './svg/rayons-anim.svg'
import {motion, AnimatePresence, AnimateSharedLayout} from 'framer-motion'

function App() {
  const [version, setVersion] = useState(integralVersion)
  var [result1, setResult1] = useState({})
  var [result2, setResult2] = useState({})
  var [resultBox, setResultBox] = useState(false)
  const [dataStatus, setDataStatus] = useState(false)

  if(!dataStatus) {
    setResult1((state) => {
        var objectResult = {}
        for (var element of version) {
           objectResult[element.name] = 0
        }
        return objectResult
    })
    setResult2((state) => {
      var objectResult = {}
      for (var element of version) {
         objectResult[element.name] = 0
      }
      return objectResult
    })
    setDataStatus(true)
  }

  const calculateResult = (objectResult) => {

      var total = 0
      var values = Object.entries(objectResult)
      var values2 = values.filter(element => element[0] !== "player")
      for(var element of values2) {
        total += parseInt(element[1])
      }

      if(isNaN(total)) {
        return 0
      }

      return total
  }

  const handleClickVersion = (data) => {
    setVersion(data)
    setResult1((state) => {
      var objectResult = {}
      for (var element of data) {
         objectResult[element.name] = 0
      }
      return objectResult
  })
  setResult2((state) => {
    var objectResult = {}
    for (var element of data ){
       objectResult[element.name] = 0
    }
    return objectResult
  })
  }

  const handleResult = () => {
    if(calculateResult(result1) === 0 && calculateResult(result2) === 0) {
      return console.log('')
    }

    setResultBox(!resultBox)
  }
  
  return (
    <div className= "app">
      <AnimatePresence exitBeforeEnter initial = {false}>
      {
        resultBox ? 
          <Result 
            player1 = {result1.player}
            player2 = {result2.player}
            result1 = {calculateResult(result1)}
            result2 = {calculateResult(result2)}
            handleClose = {handleResult}
          />
        : 
          null
      }
      </AnimatePresence>
      <div className = "app__choice">
        {
          listVersions.map((element, positionElement) => (
              <img src = {element.image} alt = {element.name} key = {`${positionElement}-listVersions`} onClick = {()=> handleClickVersion(element.data)} style = {{
                border: JSON.stringify(version) === JSON.stringify(element.data) ? '2px solid gold' : '2px solid #53aeaf', 
                boxShadow: JSON.stringify(version) === JSON.stringify(element.data) ? '1px 1px 8px 3px goldenrod' : '1px 1px 8px 3px #003737c8', 
              }}/>
          ))
        }
      </div>
      <div className = "app__container game">
        <div>
            <img src = {players} alt = "players" />
            <input className = "border" type = "text" name = "player" placeholder = "Name 1" onChange = {(e) => handleChangeInput(setResult1, e)}/>
            <input type = "text" name = "player" placeholder = "Name 2" onChange = {(e) => handleChangeInput(setResult2, e)}/>
        </div>
        <AnimatePresence>
        {
          version.map((version, positionVersion) => (
            <motion.div 
              exit = "exit"
              animate = "enter"
              initial = "initial"
              transition = {{
                type : "linear", 
                default : {duration : 0.1}
              }}
              variants = {fadeVariants}
              key = {positionVersion} style = {{ height : `${100 / version.length}%`, backgroundColor : version.color}} layout>
              <img src = {version.image} alt = "players" />
              <input className = "border" type = "number" name = {version.name} onChange = {(e) => handleChangeInput(setResult1, e)}/>
              <input type = "number" name = {version.name} onChange = {(e) => handleChangeInput(setResult2, e)} />
            </motion.div>
          ))
        }
        </AnimatePresence>

      </div>
      <div className= "app__btResult" onClick = {handleResult}>
        Résultats
      </div>
    </div>
  );
}

const fadeVariants = {
  exit : {
    opacity : 0
  }, 
  enter : {
    opacity : 1
  }, 
  initial : {
    opacity : 0
  }
}

function Result(props) {

  return (
    <motion.div 
    exit = "exit"
    animate = "enter"
    initial = "initial"
    variants = {fadeVariants}
    className = "result">
      <button onClick = {props.handleClose}>X</button>
      <img src = {victoryImg} alt = "Victoire" />
      <div className = "result__scorePlace">
        <div className = "result__scorePlace__line">
          <div className = "result__scorePlace__line__firstStone">
            <p>{props.player1}</p>
            {
              props.result1 > props.result2 ? 
                <object type="image/svg+xml" data={rays}>Rayons</object>
              : props.result1 === props.result2 ? 
                <object type="image/svg+xml" data={rays}>Rayons</object>
              
              : null
            }
            <img src = {props.result1 > props.result2 ? winStone : props.result1 === props.result2 ? winStone : bigStone} alt="Pierre de victoire" />
          </div>
          <div className = "result__scorePlace__line__secondStone">
            <p>{props.result1}</p>
            <img src = {littleStone} alt="Petite pierre" />
          </div>
        </div>
        <div className = "result__scorePlace__line">
          <div className = "result__scorePlace__line__firstStone">
            <p>{props.player2}</p>
            {
              props.result2 > props.result1 ? 
                <object type="image/svg+xml" data={rays}>Rayons</object>
              : props.result1 === props.result2 ? 
                <object type="image/svg+xml" data={rays}>Rayons</object>
              
              : null
            }
            <img src = {props.result2 > props.result1 ? winStone : props.result1 === props.result2 ? winStone : bigStone} alt="Pierre de victoire" />
          </div>
          <div className = "result__scorePlace__line__secondStone">
            <p>{props.result2}</p>
            <img src = {littleStone} alt="Petite pierre" />
          </div>
        </div>
      </div>

    </motion.div>
  )
}

export default App;


/*
      <p>{props.player1} : {props.result1}</p>
      <p>{props.player2} : {props.result2}</p>
      <p>{
        props.result1 > props.result2 ? 
          `${props.player1}`
         : 
         `${props.player2}`
      }</p>
*/