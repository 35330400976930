

export const handleChangeInput = (setState, e) => {
    if(e.target.type === "file") {
        return setState(state => {
            return {
                ...state, 
                [e.target.name] : e.target.files[0]
            }
        })
    } 

    if(e.target.type === "number") {
        return setState(state => {
            return {
                ...state, 
                [e.target.name] : parseInt(e.target.value)
            }
        })
    } 
    return setState(state => {
        return {
            ...state, 
            [e.target.name] : e.target.value
        }
    })
}