import players from '../images/players.jpg';
import blueCard from '../images/blueCard.jpg';
import greenCard from '../images/greenCard.jpg';
import yellowCard from '../images/yellowCard.jpg';
import purpleCard from '../images/purpleCard.jpg';
import purpleCardPantheon from '../images/purpleCardPantheon.jpg';
import grayCard from '../images/grayCard.jpg';
import pyramid from '../images/pyramid.jpg'
import goldPiece from '../images/goldPiece.jpg'
import greenToken from '../images/greenToken.jpg';
import militaryAdvance from '../images/militaryAdvance.jpg';
import politicians from '../images/politicians.jpg';
import classicImg from '../images/logo-duel.jpg'
import pantheonImg from '../images/logo-pantheon.jpg'
import agoraImg from '../images/logo-agora.jpg'
import integralImg from '../images/logo-integral.jpg'





export const integralVersion = [
    {
        name: "blueCard",
        image: blueCard,
        color: '#c9ebfa'
    },
    {
        name: "greenCard",
        image: greenCard,
        color: '#daead4'
    },
    {
        name: "yellowCard",
        image: yellowCard,
        color: '#fefbd9'
    },
    {
        name: "purpleCardPantheon",
        image: purpleCardPantheon,
        color: '#d1c7dc'
    },
    {
        name: "grayCard",
        image: grayCard,
        color: '#FFFFFF'
    },
    {
        name: "pyramid",
        image: pyramid,
        color: '#e7e7e8'
    },
    {
        name: "goldPiece",
        image: goldPiece,
        color: '#fefbd9'
    },
    {
        name: "greenToken",
        image: greenToken,
        color: '#daead4'
    },
    {
        name: "militaryAdvance",
        image: militaryAdvance,
        color: '#f4d1c3'
    },
    {
        name: "politicians",
        image: politicians,
        color: '#e5e5e5'
    },
]

export const classicVersion = [
    {
        name: "blueCard",
        image: blueCard,
        color: '#c9ebfa'
    },
    {
        name: "greenCard",
        image: greenCard,
        color: '#daead4'
    },
    {
        name: "yellowCard",
        image: yellowCard,
        color: '#fefbd9'
    },
    {
        name: "purpleCard",
        image: purpleCard,
        color: '#d1c7dc'
    },
    {
        name: "pyramid",
        image: pyramid,
        color: '#e7e7e8'
    },
    {
        name: "goldPiece",
        image: goldPiece,
        color: '#fefbd9'
    },
    {
        name: "greenToken",
        image: greenToken,
        color: '#daead4'
    },
    {
        name: "militaryAdvance",
        image: militaryAdvance,
        color: '#f4d1c3'
    },
]

export const pantheonVersion = [
    {
        name: "blueCard",
        image: blueCard,
        color: '#c9ebfa'
    },
    {
        name: "greenCard",
        image: greenCard,
        color: '#daead4'
    },
    {
        name: "yellowCard",
        image: yellowCard,
        color: '#fefbd9'
    },
    {
        name: "purpleCardPantheon",
        image: purpleCardPantheon,
        color: '#d1c7dc'
    },
    {
        name: "grayCard",
        image: grayCard,
        color: '#FFFFFF'
    },
    {
        name: "pyramid",
        image: pyramid,
        color: '#e7e7e8'
    },
    {
        name: "goldPiece",
        image: goldPiece,
        color: '#fefbd9'
    },
    {
        name: "greenToken",
        image: greenToken,
        color: '#daead4'
    },
    {
        name: "militaryAdvance",
        image: militaryAdvance,
        color: '#f4d1c3'
    },
]

export const agoraVersion = [
    {
        name: "blueCard",
        image: blueCard,
        color: '#c9ebfa'
    },
    {
        name: "greenCard",
        image: greenCard,
        color: '#daead4'
    },
    {
        name: "yellowCard",
        image: yellowCard,
        color: '#fefbd9'
    },
    {
        name: "purpleCard",
        image: purpleCard,
        color: '#d1c7dc'
    },
    {
        name: "pyramid",
        image: pyramid,
        color: '#e7e7e8'
    },
    {
        name: "goldPiece",
        image: goldPiece,
        color: '#fefbd9'
    },
    {
        name: "greenToken",
        image: greenToken,
        color: '#daead4'
    },
    {
        name: "militaryAdvance",
        image: militaryAdvance,
        color: '#f4d1c3'
    },
    {
        name: "politicians",
        image: politicians,
        color: '#e5e5e5'
    },
]

export const listVersions = [
    {
        name : "Classic",
        image : classicImg, 
        data : classicVersion
    },
    {
        name : "Patheon",
        image : pantheonImg, 
        data : pantheonVersion
    },
    {
        name : "Agora",
        image : agoraImg, 
        data : agoraVersion
    },
    {
        name : "Integral",
        image : integralImg, 
        data : integralVersion
    }

]